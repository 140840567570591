export function FeedbackCard({image,name,job,des}:props) {
    return (
        <div className='flex flex-col gap-5'>

            <div className='flex flex-col md:flex-row items-center gap-6'>
                <div className='w-20 h-20 rounded-full bg-[#D9D9D9] flex flex-row justify-center items-center' >
                    <img className='w-20 h-20 rounded-full' src={image}/>
                </div>

                <div className='flex flex-col'>
                    <h2 className='text-2xl font-semibold'>{name}</h2>
                    <p className='text-[0.76rem] lg:text-lg opacity-50'>{job}</p>
                </div>

            </div>

            <div>
                <p className='text-[0.9rem] lg:text-lg text-center sm:text-start opacity-50'>{des}</p>
            </div>


        </div>
    );
}

type props = {
    image: any,
    name: string,
    job: string,
    des: string
}
