import logo1 from '../../assets/images/clients/futureEyeAcademy.png';
import logo2 from '../../assets/images/clients/ceylonGuiders.png';
import logo3 from '../../assets/images/clients/machanLankaTours.png';
import logo4 from '../../assets/images/clients/sinharajaEliphinity.png';
import logo5 from '../../assets/images/clients/globeiconic.png';
import backgroundImage from "../../assets/images/dottedWorldMap.jpg";
import {HeroInfoSection} from "../../components/HeroInfoSection/HeroInfoSection";
import {ClientBox} from "../../components/ClientBox/ClientBox";
import {ContactInfoSection} from "../../components/ContactInfoSection/ContactInfoSection";
import {Helmet} from "react-helmet";

export function Clients() {

    return (
        <main className='flex flex-col gap-[50px] self-center mt-[76px] w-full 2xl:w-[1440px]'>
            <Helmet>
                <title>Nova Labs Global - Clients</title>
                <meta name="description"
                      content="Nova Labs Global values its clients as the cornerstone of its success, prioritizing their satisfaction and tailoring services to meet their unique needs."/>
                <meta name="keywords" content="nova labs global, clients, software development, customer satisfaction"/>
            </Helmet>

            <section
                className='flex flex-col w-full h-[350px] md:h-[450px] lg:h-[550px] items-center justify-center'>
                <HeroInfoSection
                    topic={'Our Valuable Clients'}
                    description={'At Nova Labs Global, we wholeheartedly believe that our clients are the kings and queens of our realm. Our commitment to excellence is built upon the foundation of understanding and prioritizing your needs. From the moment you engage with us, you become the focal point of our attention, and your satisfaction becomes our paramount goal.'}
                    projectOrClientCount={5}
                    counterLabel={'Happy Clients'}
                    backgroundImage={backgroundImage}
                />
            </section>

                <section id='section-two' className='flex items-center self-center justify-center w-[90%] mt-7'>
                    <div className='flex flex-row flex-wrap justify-center gap-3 sm:gap-4 md:gap-5 lg:gap-6 '>
                        <ClientBox image={logo1}/>
                        <ClientBox image={logo2}/>
                        <ClientBox image={logo3}/>
                        <ClientBox image={logo4}/>
                        <ClientBox image={logo5}/>

                    </div>
                </section>

                <ContactInfoSection
                    buttonName={'Contact Us'}
                    buttonLink={'/Contact'}
                    py={'py-[50px]'}
                    topic={'Do you want to be the king of our kingdom?'}
                    description={'We meticulously customize our services to precisely match your unique requirements, fostering seamless communication and unwavering dedication to excellence'}
                />
        </main>
);
}
