import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './App.css';
import {MainContent} from "./pages/MainContent/MainContent";
import {Home} from "./pages/Home/Home";
import {Company} from "./pages/Company/Company";
import {Clients} from "./pages/Clients/Clients";
import {Portfolio} from "./pages/Portfolio/Portfolio";
import {Products} from "./pages/Products/Products";
import {Careers} from "./pages/Careers/Careers";
import {Contact} from "./pages/Contact/Contact";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MainContent/>}>
                        <Route index element={<Home/>}/>
                        <Route path="company" element={<Company/>}/>
                        <Route path="clients" element={<Clients/>}/>
                        <Route path="portfolio" element={<Portfolio/>}/>
                        <Route path="products" element={<Products/>}/>
                        <Route path="careers" element={<Careers/>}/>
                        <Route path="contact" element={<Contact/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
