import {Link} from "react-router-dom";

export function ContactButton({buttonName,link}: props) {
    return (
        <Link to={link}>
            <button
                className='
                contact-btn
                p-[4px]
                mt-5 sm:mt-6 md:mt-7
                text-xl md:text-2xl
                rounded-md
                lato-regular
                w-[150px] h-[60px] md:w-[180px] md:h-[70px]
                text-white flex justify-center items-center
                bg-gradient-to-r from-[#60B2FF] to-[#7800ff] text-transparent
                hover:transition-all hover:duration-300'
            >
                {buttonName}
            </button>
        </Link>
    );
}

type props = {
    buttonName: string,
    link: any
}
