import icon1 from "../../assets/icons/layers.png";

export function Service({image,width,height,topic,description}:props) {
    return (
        <div className='services
            h-[250px] w-[320px]
            md:w-[320px]  md:h-[250px]
            lg:w-[400px]  lg:h-[250px]
            shadow-md md:shadow-lg rounded bg-white
            justify-center items-center flex flex-col
            hover:shadow-2xl hover:transition-all hover:duration-300'>
            <div
                className='flex justify-center items-center w-[50px] h-[50px] lg:w-[60px] lg:h-[60px] bg-[#292929] rounded-full'>
                <img src={image} className='w-[30px] lg:w-[34px]'/>
            </div>
            <h1 className='text-center p-2 px-4 roboto-bold text-2xl'>{topic}</h1>
            <p className='text-center p-2 px-4 roboto-regular text-gray-600'>{description}</p>
        </div>
   /* <div className='bg-white flex flex-col items-center justify-center shadow-xl rounded-xl w-[350px] h-[250px]'>
        {/!*<img src={image} width={width} height={height}/>*!/}
        <h1 className='px-10 text-center mt-3 lato-regular text-xl'>{topic}</h1>
        <p className='text-center px-5 mt-5 text-[#000000a1]'>{description}</p>
    </div>*/
)
    ;
}

type props = {
    image: any,
    width: number,
    height: number,
    topic: any,
    description: any
}
