import image from "../../../assets/images/3d-portrait-people.jpg";

export function HomePageAboutSection() {
    return (
        <section
            id='home-page-about-section'
            className="relative flex flex-col lg:flex-row w-[90%]
            h-max lg:py-[50px] pb-[50px]
            items-center justify-center self-center gap-5"
        >
            <div className='hidden lg:block w-[450px]'>
                <img src={image}/>
            </div>
            <div className='relative top-0 bottom-0 m-auto flex flex-col w-full items-center justify-center'>
                <h2 className='pl-5 self-start text-3xl'>About Us</h2>
                <p className='mt-5 lg:mt-10 text-[16px] text-left justify-center items-center px-5'>
                    <b>Nova Labs Global</b> is a cutting-edge Software Development Company specializing in Web Design, Web
                    Development, and Custom Software Solutions. Since our inception, we've been driven by a passion for
                    innovation and excellence in every project we undertake. Our team of talented engineers and
                    designers works hand-in-hand to deliver powerful solutions that transform businesses.
                    <br/>
                    <br/>
                    At Nova Labs Global, we don’t just create software; we craft experiences tailored to your unique
                    business needs. Whether you need a stunning website, a custom software application, or seamless
                    digital solutions, we’re here to make it happen.
                    <br/>
                    <br/>
                    Over the years, we’ve expanded our expertise and now proudly offer our own range of products,
                    designed and developed to solve everyday business challenges with elegance and efficiency. Our
                    journey is built on the core values of integrity, collaboration, and delivering exceptional value at
                    every step.
                    <br/>
                    <br/>
                    While our global client base continues to grow, we remain committed to offering personalized,
                    high-quality service regardless of where you are located. Whether your business is a startup or an
                    established enterprise, we’re ready to help you reach new heights with the perfect digital
                    solutions.
                    <br/>
                    <br/>
                    We look forward to partnering with you on your next project. Let’s create something extraordinary
                    together.
                </p>
            </div>
        </section>
    );
}
