import {Link, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import './Header.css';
import menu from '../../assets/icons/menu.png';
import closeMenu from '../../assets/icons/closeMenu.png';

export function NavBar() {
    useEffect(() => {
        const handleScroll = () => {
            const header = document.querySelector("#header");
            if (window.scrollY > 0) {
                // @ts-ignore
                header.classList.add("scrolled");
            } else {
                // @ts-ignore
                header.classList.remove("scrolled");
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeLink, setActiveLink] = useState("");
    const location = useLocation();

    useEffect(() => {
        // Scroll to the top whenever the route changes
        window.scrollTo(0, 0);
        setActiveLink(location.pathname);
    }, [location.pathname]);

    const menuEventHandler = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const mobileMenuClickEventHandler = () => {
        setIsMenuOpen(false);
    }

    return (
        <header id={'header'} className='fixed w-full z-50 top-0 h-[76px] flex flex-row flex-wrap'>
            <nav className='w-full flex flex-row flex-wrap sm:justify-around justify-end'>
                <menu className='hidden sm:flex flex-row align-middle gap-[35px]'>
                    <li className={`flex justify-center items-center roboto-regular ${activeLink === '/' ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#60B2FF] to-[#7800ff]' : ''}`}>
                        <Link to={''}>Home</Link>
                    </li>
                    <li className={`flex justify-center items-center roboto-regular ${activeLink === '/company' ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#60B2FF] to-[#7800ff]' : ''}`}>
                        <Link to={'/company'}>Company</Link>
                    </li>
                    <li className={`flex justify-center items-center roboto-regular ${activeLink === '/clients' ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#60B2FF] to-[#7800ff]' : ''}`}>
                        <Link to={'/clients'}>Clients</Link>
                    </li>
                    <li className={`flex justify-center items-center roboto-regular ${activeLink === '/portfolio' ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#60B2FF] to-[#7800ff]' : ''}`}>
                        <Link to={'/portfolio'}>Portfolio</Link>
                    </li>
                    <li className={`flex justify-center items-center roboto-regular ${activeLink === '/products' ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#60B2FF] to-[#7800ff]' : ''}`}>
                        <Link to={'/products'}>Products</Link>
                    </li>
                    <li className={`flex justify-center items-center roboto-regular ${activeLink === '/careers' ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#60B2FF] to-[#7800ff]' : ''}`}>
                        <Link to={'/careers'}>Careers</Link>
                    </li>
                    <li className={`flex justify-center items-center roboto-regular ${activeLink === '/contact' ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#60B2FF] to-[#7800ff]' : ''}`}>
                        <Link to={'/contact'}>Contact</Link>
                    </li>
                </menu>
                <button onClick={menuEventHandler} id={'navbar-btn'}
                        className='self-center flex justify-center items-center w-10 h-10 mr-4 sm:hidden z-50'>
                    <img src={menu} className={`w-10 ${isMenuOpen ? 'hidden' : ''}`}/>
                    <img src={closeMenu} className={`w-6 ${isMenuOpen ? '' : 'hidden'}`}/>
                </button>
                <div
                    className={`bg-[#292929] flex flex-col sm:hidden absolute m-auto py-4 top-0 left-0 right-0 ${isMenuOpen ? '' : 'hidden'}`}>
                    <menu className='sm:hidden flex flex-col align-middle gap-[10px]'>
                        <li className={`flex justify-center items-center roboto-regular ${activeLink === '/' ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#60B2FF] to-[#7800ff]' : ''}`}>
                            <Link to={''} onClick={mobileMenuClickEventHandler}>Home</Link>
                        </li>
                        <li className={`flex justify-center items-center roboto-regular ${activeLink === '/company' ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#60B2FF] to-[#7800ff]' : ''}`}>
                            <Link to={'/company'} onClick={mobileMenuClickEventHandler}>Company</Link>
                        </li>
                        <li className={`flex justify-center items-center roboto-regular ${activeLink === '/clients' ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#60B2FF] to-[#7800ff]' : ''}`}>
                            <Link to={'/clients'} onClick={mobileMenuClickEventHandler}>Clients</Link>
                        </li>
                        <li className={`flex justify-center items-center roboto-regular ${activeLink === '/portfolio' ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#60B2FF] to-[#7800ff]' : ''}`}>
                            <Link
                                to={'/portfolio'} onClick={mobileMenuClickEventHandler}>Portfolio</Link>
                        </li>
                        <li className={`flex justify-center items-center roboto-regular ${activeLink === '/products' ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#60B2FF] to-[#7800ff]' : ''}`}>
                            <Link to={'/products'} onClick={mobileMenuClickEventHandler}>Products</Link>
                        </li>
                        <li className={`flex justify-center items-center roboto-regular ${activeLink === '/careers' ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#60B2FF] to-[#7800ff]' : ''}`}>
                            <Link to={'/careers'} onClick={mobileMenuClickEventHandler}>Careers</Link>
                        </li>
                        <li className={`flex justify-center items-center roboto-regular ${activeLink === '/contact' ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#60B2FF] to-[#7800ff]' : ''}`}>
                            <Link to={'/contact'} onClick={mobileMenuClickEventHandler}>Contact</Link>
                        </li>
                    </menu>
                </div>
            </nav>
        </header>
    );
}



