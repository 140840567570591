import icon1 from "../../../assets/icons/layers.png"
import icon2 from "../../../assets/icons/crown.png"
import icon3 from "../../../assets/icons/message.png"

export function HomePageFreeSessionSection() {
    return (
        <section
            id='home-page-free-session-section'
            className='relative py-[50px] lg:py-0 lg:bottom-[50px] flex flex-col sm:flex-row w-full h-max justify-center lg:gap-7 gap-8 flex-wrap items-center'
        >
            <div className='hover:shadow-2xl hover:transition-all hover:duration-300 home-page-free-session-sub-section md:w-[250px] lg:w-[320px]
            xl:w-[400px] h-[250px] lg:h-[250px] xl:h-[250px] shadow-md md:shadow-lg rounded bg-white
            justify-center items-center flex flex-col'>
                <div className='flex justify-center items-center w-[50px] h-[50px] lg:w-[60px] lg:h-[60px] bg-[#292929] rounded-full'>
                    <img src={icon1} className='w-[30px] lg:w-[34px]'/>
                </div>
                <h1 className='text-center p-2 roboto-bold text-2xl'>Best Solutions</h1>
                <p className='text-center p-2 px-4 roboto-regular text-gray-600'>delivers innovative software engineering
                    services tailored to your business needs.</p>
            </div>
            <div className='hover:shadow-2xl hover:transition-all hover:duration-300 home-page-free-session-sub-section md:w-[250px] lg:w-[320px]
            xl:w-[400px] h-[250px] lg:h-[250px] xl:h-[250px] shadow-md md:shadow-lg rounded bg-white
            justify-center items-center flex flex-col'>
                <div className='flex justify-center items-center w-[50px] h-[50px] lg:w-[60px] lg:h-[60px] bg-[#292929] rounded-full'>
                    <img src={icon2} className='w-[30px] lg:w-[34px]'/>
                </div>
                <h1 className='text-center p-2 roboto-bold text-2xl'>Premium Quality</h1>
                <p className='text-center p-2 px-4 roboto-regular text-gray-600'>We offer premium quality software engineering services tailored to your needs. </p>
            </div>
            <div className='hover:shadow-2xl hover:transition-all hover:duration-300 home-page-free-session-sub-section md:w-[250px] lg:w-[320px]
            xl:w-[400px] h-[250px] lg:h-[250px] xl:h-[250px] shadow-md md:drop-shadow-lg rounded
            justify-center items-center flex flex-col bg-[#292929]'>
                <div className='bg-gradient-to-r from-[#60B2FF] to-[#EB00FF]
                flex justify-center items-center w-[50px] h-[50px] lg:w-[60px] lg:h-[60px] bg-[#292929] rounded-full'>
                    <img src={icon3} className='w-[30px] lg:w-[34px]'/>
                </div>
                <h1 className='text-white text-center p-2 roboto-bold text-2xl'>Free Consultation</h1>
                <p className='text-gray-100 text-center p-2 px-4 roboto-regular'>24/7 Premium Technical Support</p>
            </div>
        </section>
    );
}
