import {useEffect, useState} from "react";

export function HeroInfoSection({topic, subtopic, description, projectOrClientCount = -1, counterLabel, backgroundImage}: props) {

    let [count, setCount] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setCount(++count);
            if (count === projectOrClientCount) {
                clearInterval(interval);
            }
        }, 20);
        return () => clearInterval(interval);
    }, []);

    return (
        <section className='flex flex-col w-full h-max items-center justify-center'>
            <h1
                className='heroMainTopic px-2 sm:px-8 lg:px-16 text-center m-0
                leading-[50px] sm:leading-[60px] md:leading-[74px] lg:leading-[80px] xl:leading-[90px] 2xl:leading-[110px]
                text-[42px] sm:text-[54px] md:text-[64px] lg:text-[78px] xl:text-[88px] 2xl:text-[96px]
                roboto-bold bg-gradient-to-r from-[#60B2FF] to-[#EB00FF] text-transparent bg-clip-text'
            >
                {topic}
            </h1>
            <h1
                className='heroSubTopic px-10 xl:px-[100px] text-center m-0
                leading-[50px] sm:leading-[60px] md:leading-[74px] lg:leading-[80px] xl:leading-[90px] 2xl:leading-[110px]
                text-[38px] sm:text-[50px] md:text-[60px] lg:text-[74px] xl:text-[84px] 2xl:text-[92px]
                lato-regular'
            >
                {subtopic}
            </h1>
            <p
                className='text-[#757575] px-8 md:px-[100px] lg:px-[200px] xl:px-[280px]
                sm:text-[16px] md:text-[20px] text-[12px]
                mt-2 sm:mt-4 text-center'
            >
                {description}
            </p>
            <div className='mt-6 sm:mt-8 lg:mt-10'>
                <h1 className='heroMainTopic px-2 text-center
                    leading-[55px] sm:leading-[65px] md:leading-[75px] lg:leading-[85px] xl:leading-[95px] 2xl:leading-[110px]
                    text-[70px] sm:text-[78px] md:text-[85px] lg:text-[98px] xl:text-[120px] 2xl:text-[135px]
                     lato-bold bg-gradient-to-r from-[#60B2FF] to-[#EB00FF] text-transparent bg-clip-text'>
                    {
                        projectOrClientCount !== -1 ? count + "+" : ''
                    }
                </h1>

                <h3 className='text-center text-[16px] sm:text-[24px] lg:text-[28px] 2xl:text-[35px]'>
                    {counterLabel}
                </h3>
            </div>
            <img
                className='absolute opacity-5 -z-10'
                src={backgroundImage}
            />
        </section>
    );
}

type props = {
    topic: string,
    subtopic?: string,
    description: string,
    projectOrClientCount?: number,
    counterLabel?: string,
    backgroundImage?: any
}
