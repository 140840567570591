import {SectionTopic} from "../../components/SectionTopic/SectionTopic";
import bussinessHand from '../../assets/images/businessHand.png';
import dummyImage from '../../assets/images/feedbacks/employees/3d-illustration-business-man-with-glasses-grey-background-clipping-path.png';
import {FeedbackCard} from "../../components/FeedbackCard/FeedbackCard";
import {HeroInfoSection} from "../../components/HeroInfoSection/HeroInfoSection";
import React from "react";
import {Helmet} from "react-helmet";
import {HomePageAboutSection} from "../../components/homePageCommon/HomePageAboutSection/HomePageAboutSection";

export function Company() {
    return (
        <main className='flex flex-col self-center mt-[76px] w-full 2xl:w-[1440px]'>
            <Helmet>
                <title>Nova Labs Global - About Us</title>
                <meta name="description"
                      content="Learn more about Nova Labs Global, a software development company committed to delivering cutting-edge solutions and empowering progress through ingenious engineering."/>
                <meta name="keywords"
                      content="nova labs global, about us, company, software development, innovation, software, engineering"/>
            </Helmet>
            <section
                className='flex flex-col w-full h-[350px] md:h-[450px] lg:h-[600px] items-center justify-center'>
                {/*banner*/}
                <div
                    className='flex flex-col w-full h-[250px] sm:h-[350px] md:h-[450px] lg:h-[550px] items-center justify-center overflow-hidden'
                >
                    <HeroInfoSection
                        topic={'Who Are We'}
                        description={'Recruitment aims to find those who share our beliefs, fostering ownership and a ' +
                            'commitment to challenging norms. Nova Labs Global prioritizes people, valuing the team above all else.' +
                            ' Embracing risk with a focus on our collective vision leads to extraordinary outcomes. Success ' +
                            'is defined by how well our team performs in challenging situations.'}
                    />
                </div>
            </section>
            {/*<section id='section-two' className='flex flex-col gap-24 mx-[7vw]'>

                <div className='w-full text-center text-[20px]'>
                    <SectionTopic text={'Meet Our Team'}/>
                </div>

                <div className='w-full h-full flex flex-row flex-wrap gap-20 justify-center'>
                    <MemberCard image={achintha} name={'Achintha Jayashan'} height={'300'}
                                label={'Co-Founder and Software Engineer'}/>
                    <MemberCard image={sehan} name={'Sehan Ranaveera'} height={'350'}
                                label={'Co-Founder and Software Engineer'}/>
                    <MemberCard image={iman} name={'Iman Adithya'} height={'300'}
                                label={'Co-Founder and Software Engineer'}/>
                    <MemberCard image={ushan} name={'Ushan Kaushalya'} height={'300'}
                                label={'Co-Founder and Software Engineer'}/>
                    <MemberCard image={dilan} name={'Tharindu Dilan'} height={'300'}
                                label={'Co-Founder and Software Engineer'}/>
                    <MemberCard image={isuru} name={'Isuru Dhananjaya'} height={'300'}
                                label={'Co-Founder and Software Engineer'}/>
                </div>

            </section>*/}
            <div className='relative flex flex-col lg:flex-row w-[100%]
            h-max lg:py-[50px] pb-[50px] pt-[100px]
            items-center justify-center self-center gap-5 bg-white'>
                <HomePageAboutSection/>
            </div>

            <section
                id='section-three'
                className='w-full xl:h-[40vw] 2xl:h-[28vw] bg-center bg-cover flex flex-col lg:flex-row items-center gap-16 p-[7vw]'
                style={{backgroundImage: `url(${bussinessHand})`}}
            >

                <div className='flex flex-col gap-5'>
                    <div className='text-center'>
                        <h3 className='text-4xl md:text-5xl'>Vision</h3>
                        <p className='text-xl md:text-2xl'>Envisioning a Future of Seamless Innovation</p>
                    </div>

                    <div className='text-center text-[15px] md:text-lg'>
                        <p>
                            At Nova Labs Global, we aspire to be the catalysts of technological evolution. Our vision is
                            to create a future where software engineering transcends boundaries, unlocking
                            unprecedented
                            possibilities for businesses and individuals alike. We strive to be at the forefront of
                            innovation,
                            pioneering solutions that redefine industries and elevate the human experience.
                        </p>
                    </div>
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='text-center'>
                        <h3 className='text-4xl md:text-5xl'>Mission</h3>
                        <p className='text-xl md:text-2xl'>Empowering Progress Through Ingenious Engineering</p>
                    </div>

                    <div className='text-center text-[15px] md:text-lg'>
                        <p>
                            Our mission at Nova Labs Global is to empower progress through ingenious engineering.
                            We are committed to delivering cutting-edge software solutions that not only meet
                            but exceed the dynamic needs of our clients. By fostering a culture of continuous
                            learning,
                            collaboration, and innovation, we aim to contribute to the growth and success of our
                            clients
                            while
                            pushing the boundaries of what is technologically achievable.
                        </p>
                    </div>
                </div>
            </section>

         {/*   <section className='relative flex flex-col w-[100%]
            h-max lg:py-[50px] pb-[50px] pt-[50px]
            items-center justify-center self-center gap-5 bg-white'>
                <section className='company-name-explain-section flex justify-around flex-row flex-wrap pb-[100px] w-[90%]'>
                    <div className='company-name-explain shadow-xl p-7 rounded-lg flex items-center justify-center
                    md:w-[380px] lg:w-[450px]'>
                        <p>Nova: Like a new star in the sky, we shine through innovation. We are constantly exploring
                            new
                            horizons to deliver groundbreaking solutions, bringing fresh energy and creativity to
                            everything we do.</p>
                    </div>
                    <div className='company-name-explain shadow-xl p-7 rounded-lg flex items-center justify-center
                    md:w-[380px] lg:w-[450px]'>
                        <p>Labs: We are more than just developers—we are innovators. Our "labs" represent a space of
                            experimentation
                            and innovation, where every challenge is met with creativity and cutting-edge
                            technology.</p>
                    </div>
                </section>
                <h1 className='self-center absolute left-0 right-0 top-0 bottom-0 text-[#292929] px-10 xl:px-[100px] text-center m-0
                leading-[50px] sm:leading-[60px] md:leading-[74px] lg:leading-[80px] xl:leading-[90px] 2xl:leading-[110px]
                text-[38px] sm:text-[50px] md:text-[60px] lg:text-[74px] xl:text-[84px] 2xl:text-[92px]
                lato-bold'
                >
                    No<span
                    className='lato-bold bg-gradient-to-r from-[#60B2FF] to-[#EB00FF] text-transparent bg-clip-text'>v</span>a
                    Labs Global
                </h1>
                <section className='company-name-explain-section flex justify-around flex-row flex-wrap w-[90%] pt-[100px]'>
                    <div className='company-name-explain shadow-xl p-7 rounded-lg flex items-center justify-center
                    md:w-[380px] lg:w-[450px]'>
                        <p>Logo: The idea began with the word "Nova," which symbolizes a new star or a burst of
                            energy. From this, we selected the "V" to become the central focus of the logo, representing
                            Victory, Vision, and Versatility—the core values of our company.</p></div>
                    <div className='company-name-explain shadow-xl p-7 rounded-lg flex items-center justify-center
                    md:w-[380px] lg:w-[450px]'>
                        <p>Global: With a vision that knows no borders, we craft solutions for a connected world. Our
                            reach
                            extends across industries and countries, providing scalable and impactful solutions to
                            businesses everywhere.</p>
                    </div>
                </section>
            </section>*/}

            {/*<section id='section-four' className='m-[7vw] '>
                <div className='text-center'>
                    <SectionTopic text={'Gallery'}/>
                </div>

                <div className='flex flex-row flex-wrap justify-center gap-2 mt-10 '>

                    <div className='w-[28.3rem] h-[20rem] bg-slate-300'></div>
                    <div className='w-[28.3rem] h-[20rem] bg-slate-300'></div>
                    <div className='w-[28.3rem] h-[20rem] bg-slate-300'></div>
                    <div className='w-[28.3rem] h-[20rem] bg-slate-300'></div>
                    <div className='w-[28.3rem] h-[20rem] bg-slate-300'></div>
                    <div className='w-[28.3rem] h-[20rem] bg-slate-300'></div>

                </div>

            </section>*/}{/*gallery*/}
            <section id='home-page-customer-feedback-section'
                     className="mt-[50px] relative flex flex-col w-full h-max items-center justify-around"
            >
                <div className='flex flex-col justify-center items-center text-center'>
                    <SectionTopic text={'What Our Employees Say About Us'}/>
                    <p className='px-14 sm:[100px] md:px-[150px] lg:px-[270px] xl:px-[400px] 2xl:px-[450px] text-[#757575]'>
                        Our
                        Employee satisfaction is at the core of everything we do. Our commitment to fostering a positive
                        and supportive work environment is reflected in the experiences and feedback of our dedicated
                        team members. Here’s what some of them have to say about working with us</p>
                </div>
                <div className='mt-10 w-[90%] flex flex-col justify-center gap-10 px-5 sm:px-20'>
                    <div className='flex flex-col lg:flex-row justify-center gap-10 md:px-20'>
                        <FeedbackCard
                            image={dummyImage}
                            name={'Ravindu Kaushalya'}
                            job={'Associate Software Engineer'}
                            des={'Nova Labs Global is an incredible place to grow and thrive professionally. ' +
                                'The collaborative environment encourages creativity, and the leadership team provides ' +
                                'excellent support and opportunities for skill development. Every project we work on feels ' +
                                'like a chance to make a meaningful impact, and I’m proud to be part of such an innovative company.'}
                        />

                        <FeedbackCard
                            image={dummyImage}
                            name={'Isuru Dhananjaya'}
                            job={'Associate Software Engineer'}
                            des={'What I love most about working at Nova Labs Global is the culture of learning and teamwork.' +
                                ' There’s always something new to explore, and the company truly values the ideas and contributions ' +
                                'of every employee. The balance between cutting-edge technology and a supportive work atmosphere ' +
                                'makes it a great place to build a career.'}
                        />

                    </div>

                    {/*Slide Circles*/}
                    <div className='flex flex-row gap-3 justify-center items-center'>
                        <div className='w-4 h-4 rounded-full bg-[#03497B]'></div>
                        <div className='w-5 h-5 rounded-full bg-[#03497B]'></div>
                        <div className='w-4 h-4 rounded-full bg-[#03497B]'></div>
                    </div>

                </div>
            </section>
        </main>
    );
}
