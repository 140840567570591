import {HeroInfoSection} from "../../components/HeroInfoSection/HeroInfoSection";
import backgroundImage from "../../assets/images/binaryBackground.jpg";
import React, {useState} from "react";
import {ContactInfoSection} from "../../components/ContactInfoSection/ContactInfoSection";
import project1 from '../../assets/images/portfolio/project1.png'
import {Helmet} from "react-helmet";

export function Portfolio() {
    let [tag, setTag] = useState('all');
    let [tempTag, setTempTag] = useState('all');
    let buttonColor = 'bg-[#292929] text-white';

    function eventHandler() {
        let btn = document.getElementsByClassName(`${tag}`);
        let tempBtn = document.getElementsByClassName(`${tempTag}`);

        if (btn.length > 0) {

            // @ts-ignore
            tempBtn[0].style.backgroundColor = 'white';
            // @ts-ignore
            tempBtn[0].style.color = 'black';
            // @ts-ignore
            btn[0].style.backgroundColor = '#292929';
            // @ts-ignore
            btn[0].style.color = 'white';
        }
    }

    eventHandler();

    return (
        <main className='gap-[50px] flex flex-col self-center mt-[76px] w-full 2xl:w-[1440px] transition-all'>
            <Helmet>
                <title>Nova Labs Global - Portfolio</title>
                <meta name="description" content="Explore the successful projects completed by Nova Labs Global, showcasing our expertise in software development, mobile apps, and more." />
                <meta name="keywords" content="nova labs global, portfolio, software projects, mobile apps, web development" />
            </Helmet>
            <section
                className='flex flex-col w-full h-[350px] md:h-[450px] lg:h-[550px] items-center justify-center'>

                {/*banner*/}
                <section
                    id='portfolio-hero-section'
                    className='flex flex-col w-full h-[250px] sm:h-[350px] md:h-[450px] lg:h-[550px] items-center justify-center overflow-hidden'
                >
                    <HeroInfoSection
                        topic={"What Have We Done?"}
                        description={"Join Nova Labs Global on a journey of success. Our completed projects reflect artistry, " +
                            "dedication, and collaboration. Each project, a testament to our commitment to excellence, " +
                            "is a milestone in an ongoing narrative of triumph. Welcome to a realm where completed projects " +
                            "are not just tasks concluded; they are beacons of passion and enduring partnerships."}
                        projectOrClientCount={5}
                        counterLabel={'Complete Projects'}
                        backgroundImage={backgroundImage}
                    />
                </section>
            </section>

            <div className='flex bg-[#F9F9F9] w-full flex-col items-center'>
                <div
                    className={'portfolio-nav-bar w-3/4 h-max mt-12 flex py-2 gap-2 sm:gap-3 md:gap-4 lg:gap-5 justify-center flex-wrap'}>

                    <div
                        className={'cursor-pointer all lg:text-xl w-max h-full px-4 py-2 flex items-center justify-center rounded-xl ' +
                            `${tag === "all" ? buttonColor : "bg-white"}  font-semibold drop-shadow-md`}
                        onClick={() => {
                            setTag('all');
                            setTempTag(tag);
                            eventHandler();
                        }}
                    >
                        All
                    </div>
                    <div
                        className={'cursor-pointer web lg:text-xl w-max h-full bg-white px-4 py-2 flex items-center justify-center rounded-xl ' +
                            `${tag === "web" ? buttonColor : "bg-white"} font-semibold drop-shadow-md`}
                        onClick={() => {
                            setTag('web');
                            setTempTag(tag);
                            eventHandler();

                        }}
                    >
                        Web
                    </div>
                    <div
                        className={'cursor-pointer mobile lg:text-xl w-max h-full bg-white px-4 py-2 flex items-center justify-center rounded-xl ' +
                            `${tag === "mobile" ? buttonColor : "bg-white"} font-semibold drop-shadow-md`}
                        onClick={() => {
                            setTag('mobile');
                            setTempTag(tag);
                            eventHandler();

                        }}
                    >
                        Mobile
                    </div>
                    <div
                        className={'cursor-pointer computer lg:text-xl w-max h-full bg-white px-4 py-2 flex items-center justify-center rounded-xl ' +
                            `${tag === "computer" ? buttonColor : "bg-white"} font-semibold drop-shadow-md`}
                        onClick={() => {
                            setTag('computer')
                            setTempTag(tag);
                            eventHandler();
                        }}
                    >
                        Computer
                    </div>
                    <div
                        className={'cursor-pointer games lg:text-xl w-max h-full bg-white px-4 py-2 flex items-center justify-center rounded-xl ' +
                            `${tag === "games" ? buttonColor : "bg-white"} font-semibold drop-shadow-md`}
                        onClick={() => {
                            setTag('games');
                            setTempTag(tag);
                            eventHandler();
                        }}
                    >
                        Games
                    </div>
                    <div
                        className={'cursor-pointer ai lg:text-xl w-max h-full bg-white px-4 py-2 flex items-center justify-center rounded-xl ' +
                            `${tag === "ai" ? buttonColor : "bg-white"} font-semibold drop-shadow-md`}
                        onClick={() => {
                            setTag('ai');
                            setTempTag(tag);
                            eventHandler();
                        }}
                    >
                        AI
                    </div>
                    <div
                        className={'cursor-pointer iot lg:text-xl w-max h-full bg-white px-4 py-2 flex items-center justify-center rounded-xl ' +
                            `${tag === "iot" ? buttonColor : "bg-white"} font-semibold drop-shadow-md`}
                        onClick={() => {
                            setTag('iot');
                            setTempTag(tag);
                        }}
                    >
                        IOT
                    </div>

                </div>

                <div className={'w-full flex-wrap flex justify-center px-0 py-8 sm:p-16 gap-2'}>

                    {projects.map((project: any, index: number) => (
                        project.tag.includes(tag) && (
                            <div key={index}
                                 className={'portfolio-card md:w-[300px] lg:w-[350px] 2xl:w-[400px] w-[350px] h-[250px] ' +
                                     'bg-white shadow-xl rounded flex flex-col justify-center items-center px-4 ' +
                                     'hover:shadow-2xl hover:transition-all hover:duration-300'}>
                                <img
                                    src={require(`../../assets/images/portfolio/${project.imageName}`)}
                                    alt={project.name}
                                    className={'w-full rounded-t-[1rem] object-cover'}
                                />
                                {/*<h2 className={'text-3xl font-semibold mb-6'}>{project.name}</h2>*/}
                                {/*<p className={'text-xl text-center text-[#858686]'}>{project.description}</p>*/}
                            </div>
                        )
                    ))}

                </div>
            </div>

            <ContactInfoSection
                buttonName={'Contact Us'}
                buttonLink={'/Contact'}
                py={'py-[50px]'}
                topic={'Do you want to transform your visions into a digital reality?'}
                description={'We tailor our services to meet your specific needs with seamless communication and passion for excellence'}
            />
        </main>
    );
}

interface Project {
    name: string;
    description: string;
    imageName: string;
    tag: string
}

const projects: Project[] = [
    {
        name: "Travel agency",
        description: "Discover new horizons with this site. Intelligent booking systems, vibrant destination galleries and detailed travel plans are included to make your travel dreams a reality.",
        imageName: "project1.png",
        tag: 'web_all'
    },
    {
        name: "Travel agency",
        description: "A website designed for a travel agency. Offers seamless navigation, comprehensive travel guides and user-friendly booking options.",
        imageName: "project2.png",
        tag: 'web_all'
    },
    {
        name: "Car Rental System",
        description: "The website is designed to provide car rental operations with easy booking, real-time availability and fleet management features, increasing efficiency and customer satisfaction.",
        imageName: "project3.png",
        tag: 'web_all'
    },
    {
        name: "Car Rental System",
        description: "This website offers seamless booking processes, detailed vehicle listings and advanced management tools, providing a superior car rental experience for businesses and consumers alike.",
        imageName: "project4.png",
        tag: 'web_all'
    },
    {
        name: "Future Eye Academy",
        description: "This website offers seamless booking processes, detailed vehicle listings and advanced management tools, providing a superior car rental experience for businesses and consumers alike.",
        imageName: "project5.png",
        tag: 'web_all'
    },
];
/*;
import projects from '../consts/Projects';
import {useState} from "react";

export const Portfolio = () => {



    return (

    );
};
*/

