import {NavBar} from "../NavBar/NavBar";
import {Outlet} from "react-router";
import {FooterSection} from "../../components/FooterSection/FooterSection";

export function MainContent() {
    return (
        <>
            <NavBar/>
            <main className='flex justify-center'>
                <Outlet/>
            </main>
            <FooterSection/>
        </>
    );
}
