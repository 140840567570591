import logo1 from '../../../assets/images/clients/futureEyeAcademy.png';
import logo2 from '../../../assets/images/clients/ceylonGuiders.png';
import logo3 from '../../../assets/images/clients/machanLankaTours.png';
import logo4 from '../../../assets/images/clients/sinharajaEliphinity.png';
import logo5 from '../../../assets/images/clients/globeiconic.png';
export function HomePageClientSection() {
    return (
        <section
            id='home-page-client-section'
            className="relative flex flex-col w-full h-max items-center justify-around py-[0px]"
        >
           {/* <div className='flex flex-col justify-center items-center text-center'>
                <SectionTopic text={'Who Trust In US'}/>
                <p className='text-[#757575] sm:w-[600px] px-5'>  Leading businesses across various industries rely on us for
                    exceptional, tailored software solutions. We build lasting partnerships by consistently exceeding expectations.</p>
            </div>*/}

            <div className='overflow-hidden logos whitespace-nowrap w-full gap-[30px] flex'>
                <div className='logos-slide inline-flex gap-[30px]'>
                    <div className='w-[150px] h-[150px]'><img src={logo1} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo2} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo3} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo4} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo5} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo1} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo2} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo3} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo4} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo5} className='w-[150px] h-[150px]'/></div>
                </div>
                <div className='logos-slide inline-flex gap-[30px]'>
                    <div className='w-[150px] h-[150px]'><img src={logo1} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo2} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo3} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo4} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo5} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo1} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo2} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo3} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo4} className='w-[150px] h-[150px]'/></div>
                    <div className='w-[150px] h-[150px]'><img src={logo5} className='w-[150px] h-[150px]'/></div>
                </div>
            </div>
        </section>
    );
}
