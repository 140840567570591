import {ContactButton} from "../ContactButton/ContactButton";

export function ContactInfoSection({bgColor,py,bottom,buttonName,buttonLink,topic,description}:props) {
    return (
        <section id='contact-info-section'
                 className={`${bgColor} relative flex flex-col w-full ${py} h-max
                     items-center justify-center overflow-hidden lg:${bottom}`}>
            <h1
                className='px-[50px] sm:px-[100px] md:px-[90px] lg:px-[150px] xl:px-[200px]
                leading-[40px] md:leading-[64px] lg:leading-[77px]
                text-[36px] sm:text-[38px] md:text-[58px] lg:text-[64px]
                text-center m-0 lato-bold bg-gradient-to-r from-[#60B2FF] to-[#EB00FF] text-transparent bg-clip-text'
            >
                {topic}
            </h1>
            <p className='
            px-[70px] sm:px-[100px] md:px-[150px] lg:px-[270px] xl:px-[400px] 2xl:px-[450px]
            text-[#757575] sm:text-[16px] md:text-[20px] text-[12px] mt-2 sm:mt-4 md:mt-6 text-center'>
                {description}
            </p>
            <ContactButton  buttonName={buttonName} link={buttonLink}/>
        </section>
    );
}

type props={
    buttonName:string,
    buttonLink?:any,
    bgColor?:any,
    py?:any,
    bottom?:any,
    topic:string,
    description:string
}
