import {HeroInfoSection} from "../../HeroInfoSection/HeroInfoSection";
export function HomePageHeroSection() {
    return (
        <section id='home-page-hero-section'
                 className='bg-[#292929] flex flex-col w-full sm:h-[350px] md:h-[450px] lg:h-[600px] items-center justify-center'>
            <HeroInfoSection
                topic={'Nova Labs Global'}
                subtopic={'Transforming Visions into Digital Reality!'}
                description={'Your one-stop solution for business tech support! From web and mobile to top-notch UI/UX, we\'ve got you covered. Elevate your digital experience with us!'}
            />
        </section>
    );
}
