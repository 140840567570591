export function ClientBox({image}:props) {
    return (
        <div className='bg-white shadow-xl p-3 sm:p-4 md:p-5 lg:p-6 rounded-xl
        flex flex-row justify-center items-center
        hover:shadow-2xl hover:transition-all hover:duration-300'>
            <img src={image} className='w-16 sm:w-20 md:w-24 lg:w-28 xl:w-32'/>
        </div>
    );
}

type props={
    image:any
}
