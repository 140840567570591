import {HeroInfoSection} from "../../components/HeroInfoSection/HeroInfoSection";
import backgroundImage from "../../assets/images/binaryBackground.jpg";
import React, {useEffect, useState} from "react";
import webtemplk from '../../assets/images/product/webtemplk.png'
import apple from '../../assets/icons/app-store.png'
import google from '../../assets/icons/google-play.png'
import wide from '../../assets/icons/web.png'
import {Helmet} from "react-helmet";

export function Products() {
    const [showMore, setShowMore] = useState([false, false]);

    const toggleShowMore = (index: number) => {
        const newShowMore = [...showMore];
        newShowMore[index] = !newShowMore[index];
        setShowMore(newShowMore);
    };
    return (
        <main className='gap-[50px] flex flex-col self-center mt-[76px] w-full 2xl:w-[1440px] transition-all'>
            <Helmet>
                <title>Products - Nova Labs Global</title>
                <meta name="description"
                      content="Discover a transformative collection of products at Nova Labs Global. Engineered to change the world and enhance daily living."/>
                <meta name="keywords" content="nova labs global, software, websites, mobile apps,out products"/>
            </Helmet>
            <section className="flex flex-col justify-center items-center gap-10">
                {/*banner*/}
                <section
                    className='flex flex-col w-full h-[350px] md:h-[450px] lg:h-[550px] items-center justify-center'>

                    <HeroInfoSection
                        topic={"Our Products"}
                        description={"Discover a transformative collection of products at Nova Labs Global. Engineered to change " +
                            "the world and enhance daily living, our innovations redefine possibilities and bring simplicity " +
                            "to your life. Welcome to a realm where every product is designed to make a meaningful impact"}
                        backgroundImage={backgroundImage}
                    />
                </section>
                <div className='flex bg-[#F9F9F9] w-full flex-col items-center'>
                    <div className={'flex flex-wrap justify-center my-8 md:gap-y-12 gap-y-6'}>
                        {
                            Array.from({length: 1}, (_, index) => (
                                <div key={index}
                                     className={'shadow-xl gap-4 flex h-max w-11/12 bg-white rounded-3xl md:flex-row flex-col p-4'}>

                                    <div className={'gap-4 flex flex-col h-full md:w-1/3 w-full items-center'}>

                                        <img className={'rounded-2xl shadow'} src={webtemplk} alt=""/>

                                        <div className={'flex gap-x-4'}>

                                            {/*<div
                                                className={'flex w-12 h-12 justify-center items-center p-3 bg-gray-200 rounded-full'}>
                                                <img src={apple} alt=""/>
                                            </div>
                                            <div
                                                className={'flex w-12 h-12 justify-center items-center p-3 bg-gray-200 rounded-full'}>
                                                <img src={google} alt=""/>
                                            </div>*/}
                                            <a href="https://webtemplk.com">
                                                <div
                                                    className={'flex w-12 h-12 justify-center items-center p-3 bg-gray-200 rounded-full'}>
                                                    <img src={wide} alt=""/>
                                                </div>
                                            </a>
                                        </div>

                                    </div>

                                    <div
                                        className={'flex flex-col h-full gap-8 md:w-2/3 md:items-start items-center '}>
                                        <h2 className={'lg:text-4xl md:text-2xl text-xl font-semibold'}>WebTempLk</h2>
                                        <p className={'lg:text-xl text-sm'}>
                                            WebTemp Lk, Sri Lanka's first web template and design store. This is the first product of our company

                                        </p>
                                        <button onClick={() => toggleShowMore(index)} className={`text-blue-500 hover:underline 
                            md:hidden ${showMore[index] ? 'hidden' : 'block'} `}>
                                            See More
                                        </button>
                                        <>
                                            <p className={`lg:text-xl text-sm  md:block ${showMore[index] ? 'block' : 'hidden'} `}>
                                                WebTempLk is proud to introduce Sri Lanka's first comprehensive web template and design store, marking a significant milestone in our journey as a pioneering software engineering company. As our flagship product, WebTempLk exemplifies our commitment to innovation, quality, and providing valuable solutions to our clients.
                                            </p>
                                            <button onClick={() => toggleShowMore(index)}
                                                    className={`text-blue-500 hover:underline md:hidden ${showMore[index] ? 'block' : 'hidden'} `}>
                                                See Less
                                            </button>
                                        </>
                                    </div>

                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </main>
    );
}
