import {Link} from "react-router-dom";
import {useState} from "react";

export function FooterLink({path, text, img}: props) {
    return (
        <>
            <li className='text-[16px] text-[#757575] items-center flex flex-row gap-3 mb-3'>
                {img && <img src={img} alt={text} className='opacity-30 h-[18px] w-[18px] border-0' />}
                <Link to={path}>{text}</Link>
            </li>
        </>

    );
}

type props = {
    path?: any,
    text?: string,
    img?: any
}
