import {SectionTopic} from "../../SectionTopic/SectionTopic";
import {FeedbackCard} from "../../FeedbackCard/FeedbackCard";
import dummyImage from "../../../assets/images/dummyImage.png";
import futureEyeAcademyHarshi from "../../../assets/images/feedbacks/clients/harshi.png";


export function HomePageCustomerFeedbackSection() {
    return (
        <section id='home-page-customer-feedback-section'
                 className="relative flex flex-col w-full h-max items-center justify-around py-[50px]"
        >
            <div className='flex flex-col justify-center items-center text-center'>
                <SectionTopic text={'What Customers Say About Us'}/>
                <p className='px-14 sm:[100px] md:px-[150px] lg:px-[270px] xl:px-[400px] 2xl:px-[450px] text-[#757575]'>
                    Our clients satisfaction is at the heart of everything we do. Here’s
                    what same of
                    them have to say about their working with us</p>
            </div>
            <div className='mt-12 w-[90%] flex flex-col justify-center gap-10 px-5 sm:px-20'>
                <div className='flex flex-col lg:flex-row justify-center gap-10 md:px-20'>
                    <FeedbackCard
                        image={futureEyeAcademyHarshi}
                        name={'Harshi Kaveesha'}
                        job={'Future Eye Academy - Marketing Director'}
                        des={'Working with Nova Labs Global on our CMS website has been a fantastic experience. Their ' +
                            'team delivered a fast, user-friendly, and fully optimized platform that perfectly reflects ' +
                            'our brand. The customization and seamless integration exceeded expectations, and their ' +
                            'excellent communication kept the project on track. We’ve received great feedback from ' +
                            'students and staff, and the new website has significantly boosted our digital presence. ' +
                            'Highly recommend Nova Labs Global for top-tier web development!'}
                    />
                </div>

                {/*Slide Circles*/}
                <div className='flex flex-row gap-3 justify-center items-center'>
                    <div className='w-4 h-4 rounded-full bg-[#03497B]'></div>
                    <div className='w-5 h-5 rounded-full bg-[#03497B]'></div>
                    <div className='w-4 h-4 rounded-full bg-[#03497B]'></div>
                </div>

            </div>
        </section>
    );
}
