import {FooterLink} from "../FooterLink/FooterLink";
import emailIcon from '../../assets/icons/email.png';
import phoneIcon from '../../assets/icons/phone-receiver-silhouette.png';
import whatsapp from '../../assets/icons/whatsapp (1).png';
export function FooterSection() {
    return (
        <footer className='bg-[#292929] relative mt-[50px] flex flex-col justify-around'>
            <div className='py-6 flex flex-row flex-wrap justify-around'>
                <div className='flex flex-col w-[300px] p-5'>
                    <h1 className='mb-5 text-[19px] text-white lato-regular'>Services</h1>
                    <menu>
                        <FooterLink path={''} text={'Web Development'}/>
                        <FooterLink path={''} text={'App Development'}/>
                        <FooterLink path={''} text={'Software Development'}/>
                        <FooterLink path={''} text={'UI/UX Design'}/>
                        <FooterLink path={''} text={'ERP System Development'}/>

                    </menu>
                </div>
                <div className='flex flex-col w-[300px] p-5'>
                    <h1 className='mb-5 text-[19px] text-white lato-regular'>Company</h1>
                    <menu>
                        <FooterLink path={''} text={'About Us'}/>
                        <FooterLink path={''} text={'Our Team'}/>
                        <FooterLink path={''} text={'Gallery'}/>
                        <FooterLink path={''} text={'Feedbacks'}/>
                    </menu>
                </div>
                <div className='flex flex-col w-[300px] p-5'>
                    <h1 className='mb-5 text-[19px] text-white lato-regular'>Products</h1>
                    <menu>
                        <FooterLink path={'https://webtemplk.com'} text={'WebTempLk'}/>
                    </menu>
                </div>
                <div className='flex flex-col w-[300px] p-5'>
                    <h1 className='mb-5 text-2xl text-white lato-regular'>Contact Us</h1>
                    <menu>
                        <FooterLink text={'076 715 1321'} img={phoneIcon}/>
                        <FooterLink text={'076 715 1321'} img={whatsapp}/>
                        <FooterLink text={'info@novalabsglobal.com'} img={emailIcon}/>
                    </menu>
                </div>
            </div>
            <hr className='bg-gray-600'/>
            <div className='flex flex-col p-5 justify-center items-center'>
                <small className='text-white'>Nova Labs Global © 2024 All Rights Reserved</small>
            </div>
        </footer>
    );
}
