import web from "../../../assets/icons/browser.png";
import mobile from "../../../assets/icons/smartphone.png";
import uiux from "../../../assets/icons/ui-ux.png";
import itc from "../../../assets/icons/support.png";
import customSoftware from "../../../assets/icons/custom.png";
import ep from "../../../assets/icons/erp.png";
import {SectionTopic} from "../../SectionTopic/SectionTopic";
import {Service} from "../../Service/Service";
import {it} from "node:test";

export function HomePageServiceSection() {
    return (
        <section
            id='home-page-service-section'
            className="relative flex flex-col gap-5 w-full h-max items-center justify-around py-[50px]"
        >
            <div className='flex flex-col justify-center items-center text-center'>
                <SectionTopic text={'Our Services'}/>
                <p className='text-[#757575]'>We are your one-spot destination for a<br/>
                    comprehensive range of creative design solutions</p>
            </div>
            <div
                className='relative flex flex-col sm:flex-row w-full h-max justify-center
                lg:gap-7 gap-8 flex-wrap items-center mt-10'>
                <Service
                    image={web}
                    width={100}
                    height={100}
                    topic={'Web Development'}
                    description={'We create dynamic and responsive websites and web applications that deliver exceptional user experiences'}
                />
                <Service
                    image={mobile}
                    width={78}
                    height={85}
                    topic={'Mobile App Development'}
                    description={'We design and develop all types of high-quality mobile applications tailored to your business needs.'}
                />
                <Service
                    image={uiux}
                    width={85}
                    height={85}
                    topic={'UI/UX Designing'}
                    description={'We specialize in crafting visually stunning and highly intuitive user interfaces and user experiences with your target audience.'}
                />
                <Service
                    image={ep}
                    width={85}
                    height={85}
                    topic={'Enterprise Software Solutions'}
                    description={'We develop scalable and robust enterprise software solutions to support your business operations.'}
                />
                <Service
                    image={customSoftware}
                    width={85}
                    height={85}
                    topic={'Custom Software Development'}
                    description={'We create bespoke software solutions designed to meet your specific business needs and objectives.'}
                />
                <Service
                    image={itc}
                    width={85}
                    height={85}
                    topic={'IT Consulting'}
                    description={'We offer expert IT consulting services to help you navigate the complexities of technology and achieve your business goals.'}
                />
            </div>
        </section>
    );
}
