import {HeroInfoSection} from "../../components/HeroInfoSection/HeroInfoSection";
import {SectionTopic} from "../../components/SectionTopic/SectionTopic";
import backgroundImage from "../../assets/images/dottedWorldMap.jpg";
import React from "react";
import {Helmet} from "react-helmet";

export function Careers() {
    return (
        <main className='gap-[50px] flex flex-col self-center mt-[76px] w-full 2xl:w-[1440px] transition-all'>
            <Helmet>
                <title>Careers - Nova Labs Global</title>
                <meta name="description"
                      content="Join Nova Labs Global for a fulfilling career with competitive compensation, flexible work arrangements, continuous learning, and a supportive, inclusive culture."/>
                <meta name="keywords" content="nova labs global, careers, teem, join with us"/>
            </Helmet>
            <section className="flex flex-col justify-center items-center gap-10">

                {/*banner*/}
                <section
                    className='flex flex-col w-full h-[350px] md:h-[450px] lg:h-[550px] items-center justify-center'>

                    <HeroInfoSection
                        topic={"Careers"}
                        description={"Join Nova Labs Global for a fulfilling career with competitive compensation, flexible work arrangements,\n" +
                            "                    continuous learning, and a supportive, inclusive culture. Enjoy recognition programs and\n" +
                            "                    family-friendly\n" +
                            "                    policies. Your success is our priority. Welcome to a workplace that values you both personally and\n" +
                            "                    professionally!"}
                        backgroundImage={backgroundImage}
                    />
                </section>

                {/*interview process*/}
                <div className="px-5 py-10 bg-[#292929] w-full">
                <div className="flex flex-col justify-center items-center gap-6 text-white">
                        <SectionTopic text={"Interview Process"}/>
                        <div className="flex flex-col sm:flex-row justify-center items-center sm:items-start gap-5">

                            {/*step one*/}
                            <div className="flex flex-col justify-center items-center gap-3">
                                <div className="flex justify-center items-center w-28 h-28 bg-white rounded-full">
                                    <h1 className="text-[#292929] text-[36px]">1</h1>
                                </div>

                                <div className="text-center">
                                    <h1 className="text-2xl">Step One</h1>
                                    <p className="opacity-60 max-w-56">You for the relevant position and requirement through CV See if it fits</p>
                                </div>
                            </div>

                            {/*hr*/}
                            <div
                                className="w-1 sm:min-w-[10vw] h-24 sm:h-1 bg-white rounded-full sm:m-auto"></div>

                            {/*step two*/}
                            <div className="flex flex-col justify-center items-center gap-3">
                                <div className="flex justify-center items-center w-28 h-28 bg-white rounded-full">
                                    <h1 className="text-[#292929] text-[36px]">2</h1>
                                </div>

                                <div className="text-center">
                                    <h1 className="text-2xl">Step Two</h1>
                                    <p className="opacity-60 max-w-56">Your skills and thinking will be checked through a technical interview</p>
                                </div>
                            </div>

                            {/*hr*/}
                            <div
                                className="w-1 sm:min-w-[10vw] h-24 sm:h-1 bg-white rounded-full sm:m-auto"></div>

                            {/*step three*/}
                            <div className="flex flex-col justify-center items-center gap-3">
                                <div className="flex justify-center items-center w-28 h-28 bg-white rounded-full">
                                    <h1 className="text-[#292929] text-[36px]">3</h1>
                                </div>

                                <div className="text-center">
                                    <h1 className="text-2xl">Step Three</h1>
                                    <p className="opacity-60 max-w-56">
                                        The HR interview will check whether you are suitable for our company</p>
                                </div>
                            </div>

                        </div>
                        <p className="text-center pt-5 text-white">After the third interview round we will
                            notify you within 2-3 days if you have been selected</p>
                    </div>
                </div>

                {/*topic*/}
                <div className="p-5 text-center">
                    <p className="sm:text-xl font-bold text-[#292929]">
                        Send CV to careers@novalabsglobal.com with brief description and position applied for
                    </p>
                </div>

            </section>
        </main>
    );
}
