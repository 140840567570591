import {HeroInfoSection} from "../../components/HeroInfoSection/HeroInfoSection";
import callIcon from "../../assets/images/contact/call.png";
import emailIcon from "../../assets/images/contact/email.png";
import {Helmet} from "react-helmet";
import React from "react";
import backgroundImage from "../../assets/images/dottedWorldMap.jpg";

export function Contact() {
    return (
        <main className='gap-[50px] flex flex-col self-center mt-[76px] w-full 2xl:w-[1440px] transition-all'>

            <Helmet>
                <title>Contact Us - Nova Labs Global</title>
                <meta name="description"
                      content="Contact Nova Labs Global for expert software solutions tailored to your needs. We offer user-friendly design, robust security, and reliable performance."/>
                <meta name="keywords"
                      content="nova labs global, contact us"/>
            </Helmet>

            <section
                className='flex flex-col w-full h-[350px] md:h-[450px] lg:h-[550px] items-center justify-center'>

                <HeroInfoSection
                    topic={'Contact Us'}
                    description={'At Nova Labs Global, our expert team delivers top-tier software solutions tailored to your needs. We prioritize user-friendly design, robust security, and reliable performance to ensure your projects success. Reach out to us for exceptional service and support.'}
                    backgroundImage={backgroundImage}
                />
            </section>
            <div className="flex justify-center items-center mx-auto transition-all w-full">
                <div className="flex flex-col justify-center items-center gap-10">
                    <div
                        className="flex md:flex-row flex-col justify-center items-start gap-5 lg:gap-20 w-full p-5 mb-10">

                        <div
                            className="border-dashed border-[#9e9e9e5e] border-[1px] flex w-full flex-col justify-center
                                 items-center gap-2 p-5 py-10 hover:shadow-lg rounded-xl hover:bg-white
                                  hover:transition-all hover:duration-300 hover:border-solid">
                            <img className="w-24" src={callIcon} alt=""/>
                            <h1 className="text-[32px]">Phone</h1>
                            <p className="max-w-96 text-center">Please call us and we will be happy to assist you.
                                We work with you, not for you.</p>
                            <button className="mt-5 px-4 py-2 bg-[#292929] rounded-lg text-white font"
                                    type="button">+94
                                767 151 321
                            </button>
                        </div>

                        <div
                            className="border-dashed border-[#9e9e9e5e] border-[1px] flex w-full flex-col justify-center
                                items-center gap-2 p-5 py-10 hover:shadow-lg rounded-xl hover:bg-white
                                hover:transition-all hover:duration-300 hover:border-solid">
                            <img className="w-24" src={emailIcon} alt=""/>
                            <h1 className="text-[32px]">Email</h1>
                            <p className="max-w-96 text-center">Please email contact form and we will be happy to
                                assist you.
                                We work with you, not for you.</p>
                            <button className="mt-5 px-4 py-2 bg-[#292929] rounded-lg text-white font"
                                    type="button">info@novalabsglobal.com
                            </button>
                        </div>
                    </div>

                    {/* <div className="flex items-center justify-center w-full lg:max-w-[600px]">
                            <form className="flex-col flex gap-5 w-full">
                                <h1 className="text-[32px]">Please share your thoughts with us.</h1>

                                <input className="px-4 py-3 border-2 rounded-lg w-full" placeholder="Your Full Name"
                                       type="text"/>
                                <input className="px-4 py-3 border-2 rounded-lg w-full" placeholder="Email Address"
                                       type="email"/>
                                <input className="px-4 py-3 border-2 rounded-lg w-full" placeholder="Your Company"
                                       type="text"/>
                                <input className="px-4 py-3 border-2 rounded-lg w-full" placeholder="Your Industry"
                                       type="text"/>
                                <input className="px-4 py-3 border-2 rounded-lg w-full"
                                       placeholder="Interested Service"
                                       type="text"/>
                                <textarea className="px-4 py-2 border-2 rounded-lg w-full max-h-72 min-h-72 h-72"
                                          placeholder="Your Message"/>
                                <button className="px-4 py-3 bg-[#292929] rounded-lg text-white font"
                                        type="button">Send Your Message
                                </button>
                            </form>
                        </div>*/}
                </div>
            </div>
        </main>
    );
}
