import {HomePageHeroSection} from "../../components/homePageCommon/HomePageHeroSection/HomePageHeroSection";
import {HomePageAboutSection} from "../../components/homePageCommon/HomePageAboutSection/HomePageAboutSection";
import {
    HomePageFreeSessionSection
} from "../../components/homePageCommon/HomePageFreeSessionSection/HomePageFreeSessionSection";
import {HomePageServiceSection} from "../../components/homePageCommon/HomePageServiceSection/HomePageServiceSection";
import {HomePageClientSection} from "../../components/homePageCommon/HomePageClientSection/HomePageClientSection";
import {HomePageProjectSection} from "../../components/homePageCommon/HomePageProjectSection/HomePageProjectSection";
import {
    HomePageCustomerFeedbackSection
} from "../../components/homePageCommon/HomePageCustomerFeedbackSection/HomePageCustomerFeedbackSection";
import {ContactInfoSection} from "../../components/ContactInfoSection/ContactInfoSection";
import React from "react";
import {Helmet} from 'react-helmet';

export function Home() {
    return (
        <main className='flex flex-col self-center mt-[76px] w-full 2xl:w-[1440px]'>
            <Helmet>
                <title>Nova Labs Global - Home</title>
                <meta name="description"
                      content="Nova Labs Global is a Premier software development company delivering custom solutions in mobile apps, websites and enterprise applications to transform your business."/>
                <meta name="keywords"
                      content="nova labs global, software development, mobile apps, website development, web application development"/>
            </Helmet>
            <HomePageHeroSection/>
            <HomePageFreeSessionSection/>
           <div className='relative flex flex-col w-[100%]
            h-max lg:py-[50px] pb-[50px] pt-[100px]
            items-center justify-center self-center gap-5 bg-white'>
               <HomePageAboutSection/>
               <HomePageClientSection/>
           </div>
            <HomePageServiceSection/>
            <div className='client-and-project-counts-wrapper flex items-center flex-col py-[50px]'>
                <ContactInfoSection
                    bgColor={'bg-white'}
                    py={'py-[50px]'}
                    buttonName={'Explore more'}
                    buttonLink={'/Portfolio'}
                    topic={'Innovative Solutions for Modern Web and Mobile Applications'}
                    description={'Ready to elevate your brand to the next level? Let’s work together to drive growth. Contact us now to get started.'}
                />
                <div className='client-and-project-counts justify-around w-[90%] py-[50px] relative bg-white
                flex bottom-[30px] rounded shadow-xl flex-wrap'>
                    <div className='text-white flex flex-col items-center justify-center'>
                        <h1 className='text-[#292929] text-7xl sm:text-7xl lg:text-8xl'>5+</h1>
                        <h3 className='text-[#292929] mt-2 text-xl'>Projects Complete</h3>
                    </div>
                    <div className='border-dashed border-[#9e9e9e5e] border-[1px] client-and-project-counts-lines bg-none h-[80] w-[1px]'></div>
                    <div className='text-white flex flex-col items-center justify-center'>
                        <h1 className='text-[#292929] text-7xl sm:text-7xl lg:text-8xl'>5+</h1>
                        <h3 className='text-[#292929] mt-2 text-xl'>Happy Clients</h3>
                    </div>
                    <div className='border-dashed border-[#9e9e9e5e] border-[1px] client-and-project-counts-lines bg-none h-[80] w-[1px]'></div>
                    <div className='text-white flex flex-col items-center justify-center'>
                        <h1 className='text-[#292929] text-7xl sm:text-7xl lg:text-8xl'>100%</h1>
                        <h3 className='text-[#292929] mt-2 text-xl'>Success Rate</h3>
                    </div>
                </div>
            </div>
            {/*<HomePageClientSection/>*/}
            {/*<HomePageProjectSection/>*/}
            <HomePageCustomerFeedbackSection/>
            <ContactInfoSection
                py={'py-[50px]'}
                buttonName={'Contact Us'}
                buttonLink={'/Contact'}
                topic={'Do you want to transform your visions into a digital reality?'}
                description={'We tailor our services to meet your specific needs with seamless communication and passion for excellence'}
            />
        </main>
    );
}
